import React from 'react'
import { Link, graphql } from 'gatsby'
import Layout from '../components/Layout'
import SEO from '../components/seo'
import { Container, Row, Col } from 'react-bootstrap'
import {
  LazyLoadImage,
  LazyLoadComponent,
} from 'react-lazy-load-image-component'
import 'react-lazy-load-image-component/src/effects/blur.css'
import marked from 'marked'
class InsightPostTemplate extends React.Component {
  render() {
    const post = this.props.data.sanityInsight
    const siteTitle = post.title
    const siteDesc = post.metaDescription
    const { previous, next } = this.props.pageContext

    return (
      <Layout location={this.props.location} title={siteTitle}>
        <SEO
          title={post.metaTitle ? post.metaTitle : post.title}
          description={siteDesc}
          banner={
            post.mainImage &&
            post.mainImage.asset &&
            post.mainImage.asset &&
            post.mainImage.asset.fluid &&
            post.mainImage.asset.fluid.src
          }
          metakeywords={post.metakeywords}
        />
        <section className="insight-page">
          <Container className="py-5">
            <Row>
              <Col xs={10} sm={8} className="mx-auto">
                <div className="insight-tagline mb-4">
                  <span className="bg-blue text-white p-1 px-2 mb-4">
                    Insights
                  </span>
                </div>
                <ul className="list-unstyled d-flex align-items-center">
                  {post.authors.length !== 0 && (
                    <li className="mr-3">
                      <i className="far fa-user-circle mr-1" />
                      by{' '}
                      <span className="text-blue">
                        {post.authors[0].person.name}
                      </span>
                    </li>
                  )}

                  <li>
                    <i className="far fa-clock mr-1" />
                    {post.publishedAt ? post.publishedAt : post._createdAt}
                  </li>
                </ul>
                <LazyLoadImage
                  effect="blur"
                  className="img-fluid mb-4"
                  src={
                    post.mainImage &&
                    post.mainImage.asset &&
                    post.mainImage.asset &&
                    post.mainImage.asset.fluid &&
                    post.mainImage.asset.fluid.src
                  }
                  alt={siteTitle}
                />
                <LazyLoadComponent>
                  <div
                    className="body-markedup"
                    dangerouslySetInnerHTML={{ __html: marked(post.body) }}
                  />
                </LazyLoadComponent>
                <hr />
                <ul
                  className="list-unstyled"
                  style={{
                    display: `flex`,
                    flexWrap: `wrap`,
                    justifyContent: `space-between`,
                    listStyle: `none`,
                    padding: 0,
                  }}
                >
                  <li>
                    {previous && (
                      <Link
                        to={`/insights/${previous.slug.current}`}
                        rel="prev"
                      >
                        ← {previous.title}
                      </Link>
                    )}
                  </li>
                  <li>
                    {next && (
                      <Link to={`/insights/${next.slug.current}`} rel="next">
                        {next.title} →
                      </Link>
                    )}
                  </li>
                </ul>
                <Link to="/insights" className="btn btn-primary hvr-shadow">
                  Take Me Home
                </Link>
                <br />
                <br />
              </Col>
            </Row>
          </Container>
        </section>
      </Layout>
    )
  }
}

export default InsightPostTemplate

export const pageQuery = graphql`
  query InsightPostBySlug($id: String!) {
    site {
      siteMetadata {
        title
        author
      }
    }
    sanityInsight(id: { eq: $id }) {
      id
      excerpt
      _createdAt(fromNow: true)
      publishedAt(fromNow: true)
      title
      mainImage {
        asset {
          fluid {
            src
          }
        }
      }
      body
      metaDescription
      metaKeywords
      metaTitle
      authors {
        person {
          name
          id
          slug {
            current
          }
        }
      }
    }
  }
`
